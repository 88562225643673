import { useEffect, useReducer, useState } from 'react'
import './App.css'
import Transport from './Transport'

const transport = new Transport()

const initialState = {
  bets: [],
  bet: null,
  betId: null,
  yield: null,
  result: 1,
  status: 'loading',
  playerId: null,
  wallet: 0,
  amount: 100,
  time: 0,
  timeout: 0,
  gameId: null,
  autoCashOutAmount: null,
}

function reducer(state, { type, payload }) {
  switch (type) {
    case 'player/join':
      return { ...state, ...payload }
    case 'bet/commit':
      return { ...state, yield: payload.yield }
    case 'bet/unset':
      return { ...state, bet: null, yield: null, betId: null }
    case 'bet/set':
      return { ...state, bet: state.amount, yield: -1, betId: payload.betId }
    case 'game/idle':
      return { ...state, status: 'idle', time: payload.time, result: 1, yield: null, bet: null, bets: [], gameId: null, betId: null }
    case 'game/start':
      return { ...state, ...payload, status: 'start', result: 1 }
    case 'game/run':
      return { ...state, status: 'run', time: payload.time }
    case 'game/stop': {
      return { ...state, status: 'stop', result: payload.result, time: payload.time, timeout: payload.timeout }
    }
    case 'amount/inc':
      return { ...state, amount: state.amount + 100 }
    case 'amount/decr':
      return { ...state, amount: Math.max(state.amount - 100, 100) }
    case 'state':
      return { ...state, ...payload }
    case 'bets/update': {
      const next =  { ...state, bets: state.bets.map(cur => cur.betId === payload.betId ? { ...cur, ...payload } : cur) }

      if (state.betId === payload.betId) {
        next.yield = payload.yield
      }

      return next
    }
    case 'bets/remove':
      return { ...state, bets: state.bets.filter(cur => cur.betId !== payload.betId) }
    case 'bets/add':
      return { ...state, bets: state.bets.concat(payload) }
    default:
      return state
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const playerId = String(Date.now())

    transport.connect(process.env.REACT_APP_WS_SERVER_URL)
      .then(() => {
        transport.send('player/join', { playerId }).then(payload => {
          dispatch({ type: 'player/join', payload: { ...payload, playerId } })

          transport.addEventListener(event => {
            const { name, data } = event

            dispatch({ type: name, payload: data })

            switch (name) {
              case 'game/stop':
                transport.send('player/status').then(payload => {
                  dispatch({ type: 'state', payload })
                })
                break
              default:

            }

          })
        })
      })
  }, [])

  useEffect(() => {
    let timerId

    switch (state.status) {
      case 'stop':
      case 'start':
        timerId = setTimeout(() => {
          const now = Date.now()
          const timeout = Math.max((state.timeout - (now - state.time)), 0)
          dispatch({ type: 'state', payload: { time: now, timeout } })
        }, 100)
        break
      case 'run':
        timerId = setTimeout(() => {
          const now = Date.now()
          const time = (now - state.time) / 1000
          const result = Math.round(Math.max(0.972 * Math.exp(0.0606 * time) , 1) * 100) / 100
          dispatch({ type: 'state', payload: { result } })
        }, 100)
        break
      default:
        // skip
    }

    return () => {
      clearTimeout(timerId)
    }
  })

  if ('loading' === state.status) {
    return (
      <div className="App">
        <div className="Placeholder">Loading...</div>
      </div>
    )
  }

  return (
    <div className="App">
      <div className="App-player">
        <div># {state.playerId}</div>
        <div>{money(state.wallet)}</div>
      </div>
      <div className="Grid-root">
        <div className="Grid-bets">
          <Bets state={state}/>
        </div>
        <div className="Grid-canvas">
          <div className="Status-root">
            <Status state={state}/>
          </div>
          <div className="Control-root">
            <Button
              state={state}
              onBetSet={handleBetSet}
              onBetUnset={handleBetUnset}
              onCashOut={handleCashOut}
              onAmountInc={handleAmountInc}
              onAmountDecr={handleAmountDecr}
              onAutoCashOutChange={handleAutoCashOutChange}
            />
          </div>
        </div>
      </div>
    </div>
  )


  function handleAmountInc() {
    dispatch({ type: 'amount/inc' })
  }

  function handleAmountDecr() {
    dispatch({ type: 'amount/decr' })
  }

  function handleBetSet() {
    transport.send('bet/set', { autoCommit: state.autoCashOutAmount, playerId: state.playerId, gameId: state.gameId, amount: state.amount }).then(payload => {
      dispatch({ type: 'bet/set', payload })
    })
  }

  function handleBetUnset() {
    transport.send('bet/unset', { playerId: state.playerId, gameId: state.gameId, betId: state.betId }).then(payload => {
      dispatch({ type: 'bet/unset', payload })
    })
  }

  function handleCashOut() {
    transport.send('bet/commit', { playerId: state.playerId, gameId: state.gameId, betId: state.betId }).then(payload => {
      dispatch({ type: 'bet/commit', payload })
    })
  }

  function handleAutoCashOutChange(payload) {
    dispatch({ type: 'state', payload })
  }
}

function Status(props) {
  const { state } = props

  switch (state.status) {
    case 'idle':
      return <div className="Status-text">Waiting for bets</div>
    case 'start':
      return (
        <div className="Status-text">
          <div>Starts in:</div>
          <div>{(state.timeout / 1000).toFixed(2)}s</div>
        </div>
      )
    case 'run':
      return (
        <div className="Status-text">
          <div className="Status-result">{state.result.toFixed(2)}x</div>
        </div>
      )
    case 'stop':
      return (
        <div className="Status-text done">
          <div className="Status-result">{state.result.toFixed(2)}x</div>
          <div>Next round: {(state.timeout / 1000).toFixed(2)}s</div>
        </div>
      )
    default:
      return null
  }
}

function Bets(props) {
  const { state } = props

  const children = state.bets.map((cur, i) => {
    return (
      <tr key={cur.betId} className={cur.yield > 0 ? 'cashout' : null}>
        <td>{cur.playerId}</td>
        <td>{money(cur.amount)}</td>
        <td>{cur.yield ? cur.yield : '-'}</td>
        <td>{cur.yield ? money(cur.amount * cur.yield) : '-'}</td>
      </tr>
    )
  })

  return (
    <table className="Bets-table">
      <thead>
        <tr>
          <th>Player</th>
          <th>Bet</th>
          <th>Mult.</th>
          <th>Win</th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  )
}

function Button(props) {
  const { state, onAmountInc, onAmountDecr, onCashOut, onBetSet, onBetUnset, onAutoCashOutChange } = props

  switch(state.status) {
    case 'idle':
    case 'start':
      if (state.bet) {
        return <div className="Button-cancel" onClick={onBetUnset}>Cancel</div>
      } else {
        return (
          <div>
            <div className="Button-group">
              <div className="Button-ctrl" onClick={onAmountInc}>+1</div>
              <div className="Button-ctrl" onClick={onAmountDecr}>-1</div>
              <div className="Button-bet" onClick={onBetSet}>Bet {money(state.amount)}</div>
            </div>
            <AutoCashOut amount={state.autoCashOutAmount} onChange={onAutoCashOutChange}/>
          </div>
        )
      }
    case 'run': {
      if (state.bet && -1 === state.yield) {
        return <div className="Button-cashout" onClick={onCashOut}>Cashout: {money(state.result * state.bet)}</div>
      }
      return <div className="Button-bet disabled">Waiting...</div>
    }
    case 'stop':
      return <div className="Button-bet disabled">Waiting...</div>
    default:
      return <div className="Button-bet disabled">Waiting...</div>
  }
}

function AutoCashOut(props) {
  const { amount, onChange } = props
  const [value, setValue] = useState(() => (amount ? amount : ''))
  const disabled = null === amount

  return (
    <div className="AutoCashOut">
      <label>
        Auto Cash out
        <input defaultChecked={!disabled} type="checkbox" onChange={handleToggle}/>
      </label>
      <input type="number" value={value} disabled={disabled} onChange={handleChange} onBlur={handleBlur} />
    </div>
  )

  function handleToggle(e) {
    onChange({ autoCashOutAmount: e.target.checked ? 1.10 : null })
    setValue(e.target.checked ? '1.10' : '')
  }

  function handleChange(e) {
    setValue(e.target.value)
  }

  function handleBlur() {
    if ('' === value || '0' === value || Number(value) < 1.01) {
      onChange({ autoCashOutAmount: 1.10 })
      setValue('1.10')
    } else {
      onChange({ autoCashOutAmount: Number(value) })
    }
  }
}

function money(value) {
  return (value / 100).toFixed(2) + '$'
}

export default App;