class Transport {
  constructor() {
    this.ready = false
    this.queue = []
    this.listeners = []
    this.resolvers = {}
    this.requestId = 0
  }

  connect(url) {
    return new Promise(resolve => {
      this.socket = new WebSocket(url)
      this.socket.addEventListener('open', () => {
        this.socket.addEventListener('message', e => {
          const message = JSON.parse(e.data)

          switch (message.type) {
            case 'response':
              const resolver = this.resolvers[message.id]

              if (resolver) {
                console.log('response', { id: message.id, success: message.success, data: message.data  })
                resolver(message.data)
                delete this.resolvers[message.id]
              }
              break
            case 'event':
              console.log('event', { name: message.name, data: message.data })
              for (let i = 0; i < this.listeners.length; i++) {
                const fn = this.listeners[i]
                fn({ name: message.name, data: message.data })
              }
              break;
            default:
              console.log('unknown message type', message.type)
          }
        })
        this.ready = true
        resolve()
      })
    })
  }

  send(method, data = {}) {
    return new Promise(resolve => {
      const requestId = String(Date.now())

      console.log('request', { id: requestId, method, data })
      this.resolvers[requestId] = resolve
      this.socket.send(JSON.stringify({ type: 'request', id: requestId, method, data }))
    })
  }

  addEventListener(callback) {
    this.listeners.push(callback)
  }
}

export default Transport